body {
  --mdc-typography-button-font-family: "Open Sans", sans-serif;
  --mdc-typography-button-letter-spacing: 0;
  --mdc-tab-indicator-active-indicator-color: var(--primary);

  .mat-mdc-outlined-button:not([disabled]) {
    border-color: var(--primary);
    mat-icon {
      color: var(--primary);
    }
  }
  .mat-mdc-unelevated-button.mat-primary:not([disabled]) {
    background: var(--primary);
  }
  .mdc-button__label {
    line-height: 1em !important;
  }
  .mat-mdc-tab-body {
    padding-top: 30px;
  }
  .mat-mdc-tab-labels {
    border-bottom: 1px solid var(--border-color);
  }
  .mat-mdc-tab {
    font-weight: 700;
  }
  .mat-mdc-tab-body-content {
    padding: 40px 30px;
    border: 1px solid var(--border-color);
  }
  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: var(--primary);
  }
}
