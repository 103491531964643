@use 'sass:math';
// All hardcoded variabels

// None changeing colors
$master-background: #f3f3f3;
$master-heading: #181818;
$master-text: #393939;

// Master
$master-font-size: 14px;
$master-line-height: 1.6;
$master-font-weight: 400;
$spacer: 1rem;

// Typography
$master-font-family: "Open Sans", sans-serif;

$headings-margin-bottom: math.div($spacer, 2);
$headings-font-family: inherit;
$headings-font-weight: 600;
$headings-line-height: 1.2;
$headings-color: $master-heading;

$h1-font-size: $master-font-size * 2;
$h2-font-size: $master-font-size * 1.75;
$h3-font-size: $master-font-size * 1.50;
$h4-font-size: $master-font-size * 1.25;
$h5-font-size: $master-font-size * 1.15;
$h6-font-size: $master-font-size;

// Breakpoints
$xsmall-min-width: 600px;
$small-min-width: 960px;
$medium-min-width: 1280px;
$large-min-width: 1920px;

// Querys
$screen: "only screen";
$xsmall: "only screen and (min-width: #{$xsmall-min-width})";
$small: "only screen and (min-width: #{$small-min-width})";
$small-and-down: "only screen and (max-width: #{$small-min-width})";
$medium: "only screen and (min-width: #{$medium-min-width})";
$large: "only screen and (min-width: #{$large-min-width})";
$landscape: "only screen and (orientation: landscape)";
$portrait: "only screen and (orientation: portrait)";
