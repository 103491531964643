@import 'app/shared/styles/variabels';
@import 'app/shared/styles/normalize';
@import 'app/shared/styles/typography';
@import 'app/shared/styles/material-reset';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background: var(--background);
}

* {
  box-sizing: border-box;
}

textarea {
  box-sizing: content-box;
}

.content {
  padding: 40px 70px 60px;
  height: 100%;
  overflow: auto;
  max-height: calc(100vh - 40px);
}

section {
  margin-bottom: 70px;
}

.background-box {
  background: var(--background);
}

.border-box {
  border: 2px solid var(--background);
  padding: 15px;
}

.flex-wrap-layout {
  margin: 0 -20px;
  flex-wrap: wrap;
  & > * {
    padding: 0 20px;
  }
  &.small {
    margin: 0 -5px;
    & > * {
      padding: 0 5px;
    }
  }
}

.content-header {
  margin: 0 0 60px 0;
  border-bottom: 2px solid var(--background);
  padding: 0 0 20px 0;
  h2 {
    margin: 0;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #ddd;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #666;
}
